<template>
  <v-container id="club" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8"></v-col>
    </v-row>
  </v-container>
</template>

<script> 
export default {
  //
};
</script>
